<template>
    <DefaultLayout
    :pageTitle="pageTitle"
    :pageSubtitle="pageSubtitle"
    :subpageLinks="subpageLinks"
    >
    <div class="unit-dashboard-body">
        <div style="width: 100%; display: flex;">
            <div class="left-container">
                <div v-if="gauges" class="gauge-container">
                    <GenericGauge v-for="(gauge, index) in gauges" :key="index"
                        :dataID="dataID"
                        :template="gauge"
                        :timeFormat="timeFormat"
                    ></GenericGauge>
                </div>

                <div v-if="infoCards" class="unit-state-container">
                    <div v-for="(statDetails, index) in infoCards" :key="index" class="custom-stat">
                        <StatsCard
                            :statDetails="statDetails"
                            :dataID="dataID"
                            :timeFormat="timeFormat"
                        ></StatsCard>
                    </div>
                </div>
            </div>

            <div class="right-container">
                <UnitMap
                    :dataID="dataID"
                ></UnitMap>
            </div>
        </div>

        <div v-for="(table, index) in tables" :key="index" class="diagnostics-container" style="width: 99%; margin-top: 20px;">
            <GenericTable v-if="table.type === 'generic'"
                :dataID="dataID"
                :template="table"
                :timeFormat="timeFormat"
            ></GenericTable>
        </div>

        <div v-if="statistics.length" class="statistics-container">
            <h1 style="margin-left: 15px;">Statistics</h1>
            <div class="statistics-body">
                <StatsCard v-for="(statDetails, index) in statistics" :key="index"
                    :statDetails="statDetails"
                    :dataID="dataID"
                    :timeFormat="timeFormat"
                ></StatsCard>
            </div>
        </div>

        <div :class="statistics.length ? 'dashboard-container-1' : 'dashboard-container-2'">
            <div class="event-log-container" style="flex-grow: 1; margin-right: 20px;">
                <EventLog style="margin-bottom: 15px;"
                    :dataID="dataID"
                    :timeFormat="timeFormat"
                ></EventLog>
            </div>

            <!-- <div style="background-color: white; padding: 20px; border-radius: 5px; flex-grow: 1; margin-right: 20px; height: fit-content;">
                <ExportDataCard style="height: fit-content;"></ExportDataCard>
            </div> -->
        </div>
    </div>
  
    </DefaultLayout>
</template>
    
<script>
    import { useStore } from 'vuex';
    import { onMounted, ref } from 'vue';
    import { router } from '@/router';
    import { doc, getDoc, onSnapshot } from 'firebase/firestore';
    import { db } from '@/firebase';
    import { localToUTC, utcToLocal, timeSimplified } from '@/tools/TimeFunctions';

    import DefaultLayout from '@/layouts/Layout.vue';
    import GenericGauge from '@/components/charts/Guage.vue';
    import StatsCard from '@/components/cards/StatsCard.vue';
    // import ExportDataCard from '@/components/cards/ExportDataCard.vue';
    import GenericTable from '@/components/tables/GenericTable.vue';
    import EventLog from '@/components/tables/EventLog.vue';
    import UnitMap from '@/components/maps/UnitMap.vue';
    
    export default {
        name: 'UnitDashboard',
        components: {
            DefaultLayout,
            GenericGauge,
            StatsCard,
            // ExportDataCard,
            GenericTable,
            EventLog,
            UnitMap
        },
        setup() {
            // Set up reference variables.
            const pageTitle = ref('');
            const pageSubtitle = ref('');
            const subpageLinks = ref(new Map());
            const statistics = ref(new Object());
            const gauges = ref(new Object());
            const tables = ref(new Object());
            const infoCards = ref(new Object());
            const mapData = ref(new Map());
            const showMap = ref(false);
            const mapStartDate = ref('');
            const mapEndDate = ref('');
            const timeline = ref(new Map());

            // Set up all other variables
            const store = useStore();
            const timeFormat = store.state.user.timeFormat;
            const dataID = router.currentRoute.value.params.dataID;
            let unitDetails = null;

            onMounted(async () => {
                await getUnitDetails();
                getTemplate();
                getLocation();
            });

            /**
             * Grabs the unit details and sets up the page title
             */
            const getUnitDetails = async() => {
                
                // Attempt the data document using the data ID
                try {
                    const docRef = doc(db, "data", dataID);
                    const docSnap = await getDoc(docRef);

                    if (docSnap.exists()) {
                        unitDetails = docSnap.data();
                        if (unitDetails.friendlyName !== "") {
                            pageTitle.value = unitDetails.friendlyName;
                            pageSubtitle.value = `<p>
                                <span style="font-weight: bold">Data ID: </span>
                                ${dataID}
                                <span style="font-weight: bold; margin-left: 20px;"> Last Active: </span>
                                ${timeSimplified(utcToLocal(unitDetails.lastActivityUTC, timeFormat))}
                                </p>`;
                        
                        } else {
                            pageTitle.value = dataID;
                            pageSubtitle.value = `<p>
                                <span style="font-weight: bold;">Last Active: </span>
                                ${timeSimplified(utcToLocal(unitDetails.lastActivityUTC, timeFormat))}
                                </p>`;
                        }

                    } else {
                        pageTitle.value = dataID + " (NON-EXISTANT)";
                    }
                } catch(error) {
                    console.log(error)
                    pageTitle.value = dataID;
                    pageSubtitle.value = "Faild to retrieve data";
                }
            };

            /**
             * Grabs the template for the unit and adds additional subpage links
             */
            const getTemplate = async() => {

            // The template is not stored so grab it from the database.
                try {
                    const docRef = doc(db, "templates", unitDetails.defaultTemplate);
                    const docSnap = await getDoc(docRef);

                    if (docSnap.exists()) {
                        // Grab the document data and store it
                        const templateData = docSnap.data();

                        // Grab the statistics.
                        statistics.value = templateData.overview.statistics || [];
                        tables.value = templateData.overview.tables;
                        gauges.value = templateData.overview.gauges;
                        infoCards.value = templateData.overview.infoCards;

                        // Add any other additional subpage links
                        subpageLinks.value.set('Overview', [null, 'inactive']);

                        if (templateData.timeSeries) {
                            subpageLinks.value.set('Time Series', [`/dashboard/${dataID}/time-series`]);
                        }

                        if (templateData.events) {
                            subpageLinks.value.set('Events', [`/dashboard/${dataID}/events`]);
                        }
                    }

                } catch(error) {
                    console.log(error);
                }            
            };

            const getLocation = () => {
                
                // Attempt the data document using the data ID
                try {
                    onSnapshot(doc(db, "data", dataID), (document) => {
                        showMap.value = false;
                        const data = document.data();
                        mapData.value.set(dataID, data);
                        showMap.value = true;
                    });
                } catch(error) {
                    console.log(error)
                }
            };

            const generateTimeline = () => {
                const utcStartDate = localToUTC(mapStartDate.value);
                const utcEndDate = localToUTC(mapEndDate.value);

                showMap.value = false;
                timeline.value.set('startDate', utcStartDate);
                timeline.value.set('endDate', utcEndDate);
                showMap.value = true;
            };

            return {
                pageTitle,
                pageSubtitle,
                subpageLinks,
                dataID,
                timeFormat,
                mapData,
                showMap,
                statistics,
                gauges,
                infoCards,
                tables,
                mapStartDate,
                mapEndDate,
                timeline,
                generateTimeline
            }
        }
    };
</script>
    
<style>

    .unit-dashboard-body {
        display: flex;
        flex-wrap: wrap;
        height: calc(100vh - 220px);
        /* height: fit-content; */
    }

    .left-container {
        max-width: 700px;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
    }

    .right-container {
        flex-grow: 1;
        /* min-width: 500px; */
        margin-left: 20px;
        margin-right: 20px;
        margin-bottom: 40px;
        /* height: 485px; */
    }

    .statistics-container {
        width: 50%;
        background-color: white;
        border-radius: 10px;
        margin-right: 20px;
        height: 583px;
    }

    .gauge-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        justify-content: space-around;
        gap: 10px;
    }

    .unit-state-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        justify-content: space-around;
        gap: 20px;
    }

    .custom-stat {
        background-color: white;
        width: 300px;
        border-radius: 5px;
        /* padding: 20px; */
        display: flex;
    }

    .diagnostics-container {
        margin-bottom: 40px;
        width: 100%;
    }

    .statistics-body {
        height: 81%;
        overflow: auto;
    }

    .dashboard-container-1 {
        flex-grow: 1;
    }

    .dashboard-container-2 {
        display: flex;
        width: 100%;
    }
</style>
<template>
    <div class="run-and-charge-container">
        <h1 class="mt-0 mb-3 text-2xl">Run And Charge Events</h1>
        <div class="flex items-center mb-1">
            <DatePicker :formatter="formatter" v-model="dateValue" style="width: 450px; margin-right: 20px;"></DatePicker>
            <button v-if="!rangeSet" :disabled="loading" @click="setRange" :class="{ 'bg-indigo-600 shadow-gray-500 shadow-lg hover:bg-indigo-500': !loading, 'bg-indigo-400': loading }" class="rounded-md p-5 py-2 text-xs font-semibold text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Set Range</button>
            <button v-else @click="currentEvents" class="bg-indigo-600 shadow-gray-500 shadow-lg hover:bg-indigo-500 rounded-md p-5 py-2 text-xs font-semibold text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Back To Last 24hrs</button>
            <LoadingAnimation1 v-if="loading" class="mx-5"></LoadingAnimation1>
        </div>
        <br>
        <p v-if="!rangeSet">Events within the last 24 hours</p>
        <table>
            <thead style="display: block; width: 100%; display: table;">
                <tr>
                    <th style="width: 1%"><input type="checkbox" @change="toggleAll"></th>
                    <th v-for="column in tableData.keys()" :key="column">{{ column }}</th>
                </tr>
            </thead>
            <tbody style="display: block; height: auto; max-height: 400px; overflow: auto; width: 100%;">
                <tr v-for="index in tableData.get('Start Timestamp').length" :key="index" :class="{'alternate-color': index % 2 !== 0}">
                    <td style="width: 25px;"><input type="checkbox" @change="toggleRow(index-1)" :checked="checkAll"></td>
                    <td v-for="value in tableData.values()" :key="value[index-1]">{{ value[index-1] }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
/* eslint-disable vue/no-setup-props-destructure */
    import { onMounted, ref } from 'vue';
    import { collection, orderBy, query, where, getDocs } from 'firebase/firestore';
    import { db } from '@/firebase';

    import { findDifference, getPastTimestamp, utcToLocal, localToUTC } from '@/tools/TimeFunctions';
    import LoadingAnimation1 from '../loadingIcons/LoadingAnimation1.vue';

    export default {
        name: 'EventsTable',
        components: {
            LoadingAnimation1
        },
        props: {
            dataID: String,
            source: String,
            template: Object,
            timeFormat: {
                type: String,
                default: '24hour'
            }
        },
        setup(props) {
            const tableData = ref(new Map([
                ['Start Timestamp', []],
                ['End Timestamp', []],
                ['Total Elapsed Time', []],
                ['Tag', []],
            ]));
            const formatter = ref({
                date: "YYYY-MM-DD HH:mm:ss",
                month: "MMM"
            });
            const dateValue = ref({
                startDate: "",
                endDate: "",
            });
            const rangeSet = ref(false);
            const loading = ref(false);
            const checkAll = ref(false);
            const checkedRows = ref([]);

            /**
             * Grab data from the data collection
             */
            const currentEvents = onMounted(async() => {
                // clear the table.
                tableData.value.forEach((value, key) => {
                    tableData.value.set(key, []);
                });

                rangeSet.value = false;

                // Grab the last 24 hours of events
                const q = query(collection(db, 'data', props.dataID, props.source), where('messageUTC', '>=', getPastTimestamp(24)), orderBy('messageUTC', 'asc'));
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                    generateEvents(doc.data());
                });

                // onSnapshot(doc(db, props.source, props.dataID), (document) => {
                //     const docData = document.data();
                //     let timestamps = tableData.value.get('Time Stamp');
                //     let events = tableData.value.get('Event')

                //     timestamps.unshift(utcToLocal(docData.lastActivityUTC, props.timeFormat));
                //     events.unshift(docData.lastMessage);

                //     if (timestamps.length > 5) {
                //         timestamps.pop();
                //         events.pop();
                //     }

                //     tableData.value.set('Time Stamp', timestamps);
                //     tableData.value.set('Event', events);
                // });
            });

            /**
             * Group data together based on the type of event, and wether its a previous event
             * or current.
             */
            const generateEvents = (document) => {
                // Determine the event type
                let eventType;
                if (document.c_mean > 0) {
                    eventType = 'Running';
                }
                else if (document.c_mean < 0) {
                    eventType = 'Charging';
                }

                // Ignore any messages where current is 0
                else {
                    return;
                }

                // check how far appart each message is in seconds.
                const timeDifference = findDifference(tableData.value.get('End Timestamp')[0], utcToLocal(document.messageUTC, props.timeFormat))[1];

                // // If the timeDifference is large, create an "Off" event.
                // if (timeDifference > 65) {
                //     tableData.value.get('Start Timestamp').unshift(tableData.value.get('End Timestamp')[0]);
                //     tableData.value.get('End Timestamp').unshift(utcToLocal(document.messageUTC, props.timeFormat));
                //     tableData.value.get('Total Elapsed Time').unshift(findDifference(tableData.value.get('Start Timestamp')[0], tableData.value.get('End Timestamp')[0])[0]);
                //     tableData.value.get('Total Amp Hours').unshift(0);
                //     tableData.value.get('Total kwh').unshift(0);
                //     tableData.value.get('Event Type').unshift('Off');
                // }

                // Start a new event
                if (tableData.value.get('Event Type')[0] !== eventType || timeDifference > 65) {
                    tableData.value.get('Start Timestamp').unshift(utcToLocal(document.messageUTC, props.timeFormat));
                    tableData.value.get('End Timestamp').unshift(utcToLocal(document.messageUTC, props.timeFormat));
                    tableData.value.get('Total Elapsed Time').unshift('0 day(s) 00:00:30');
                    tableData.value.get('Total Amp Hours').unshift((document.kwh * 1000)/document.v_mean);
                    tableData.value.get('Total kwh').unshift(document.kwh);
                    tableData.value.get('Event Type').unshift(eventType);
                }


                // Continue building on an event
                else {
                    tableData.value.get('End Timestamp')[0] = utcToLocal(document.messageUTC, props.timeFormat);
                    tableData.value.get('Total Elapsed Time')[0] = findDifference(tableData.value.get('Start Timestamp')[0], tableData.value.get('End Timestamp')[0])[0];
                    tableData.value.get('Total Amp Hours')[0] += (document.kwh * 1000)/document.v_mean;
                    tableData.value.get('Total kwh')[0] += document.kwh;
                }
            };

            /**
             * Sets the time range of which documents to grab
             */
            const setRange = async() => {
                // Start the loading animation
                loading.value = true;

                // clear the table.
                tableData.value.forEach((value, key) => {
                    tableData.value.set(key, []);
                });

                // Grab the utc start and end date.
                const utcStart = localToUTC(dateValue.value.startDate);
                const utcEnd =  localToUTC(dateValue.value.endDate);

                // Grab documents within set dates. 
                const q = query(collection(db, 'data', props.dataID, props.source), where('messageUTC', '>=', utcStart), where('messageUTC', '<=', utcEnd), orderBy('messageUTC', 'asc'));
                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                    generateEvents(doc.data());
                });

                // Stop Loading animation and show "back to current" button.
                loading.value = false;
                rangeSet.value = true;
            };

            const toggleRow = (dataIndex) => {
                const index = checkedRows.value.indexOf(dataIndex);
                if (index === -1) {
                    checkedRows.value.push(dataIndex);
                } else if (index === 0) {
                    checkedRows.value.splice(0, 1);
                } else {
                    checkedRows.value.splice(index, index);
                }
            };

            const toggleAll = () => {
                checkAll.value = !checkAll.value;

                if (checkAll.value) {
                    const length = console.log(tableData.value.get('Start Timestamp').length);
                    for (let dataIndex in length) {
                        console.log(dataIndex);
                        if (checkedRows.value.indexOf(dataIndex-1) === -1) {
                            checkedRows.value.push(dataIndex-1);
                        }
                    }
                } else {
                    checkedRows.value = [];
                }
            }

            return {
                tableData,
                formatter,
                dateValue,
                rangeSet,
                loading,
                checkAll,
                checkedRows,
                currentEvents,
                setRange,
                toggleRow,
                toggleAll,
            };
        },
    };
</script>

<!-- <style>
    table {
    width: 100%;
    background-color: white;
    border-collapse: collapse;
    }

    th {
        background-color: rgb(48, 145, 255);
    }

    th, td {
    /* border: 1px solid black; */
    box-sizing: border-box;
    padding: 8px;
    text-align: left;
    width: 14.29%;
    }

    tr {
        height: 35px;
        width: 100%;
        display: table;
        /* table-layout: fixed; */
    }

    .run-and-charge-container {
        height: auto;
        max-height: 575px;
        /* overflow: hidden; */
        background-color: white;
        border-radius: 5px;
        padding: 10px;
    }

    .alternate-color {
        background-color: rgb(211, 211, 211);
    }
</style> -->
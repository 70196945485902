<template>
    <Menu as="div" class="relative inline-block text-left">
      <div>
        <MenuButton class="flex items-center">
          <span class="text-sm font-semibold leading-6 text-gray-900">{{ buttonString }}</span>
          <ChevronDownIcon v-if="addIcon" class="h-5 w-5" aria-hidden="true" />
        </MenuButton>
      </div>
  
      <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
        <MenuItems class="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div class="py-1">
            <MenuItem v-for="details in dropdownItems" :key="details.name" v-slot="{ active }">
              <a :href="details.href" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">{{ details.name }}</a>
            </MenuItem>
          </div>
        </MenuItems>
      </transition>
    </Menu>
  </template>
  
  <script>
  import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
  import { ChevronDownIcon } from '@heroicons/vue/20/solid'
  
  export default {
      name: "SimpleDropdown",
      components: {
          Menu,
          MenuButton,
          MenuItem,
          MenuItems,
          ChevronDownIcon
      },
      props: {
          buttonString: String,
          dropdownItems: Array,
          addIcon: {
            type: Boolean,
            default: true,
        }
      },
      setup() {
        
      },
  };
  </script>
<template>
  <header class="bg-blue-300">
    <nav class="mx-auto flex items-center justify-between p-6 lg:px-8" aria-label="Global">
      <div class="flex items-center justify-between -my-5">
        <img class="h-12 w-auto" :src="companyLogo" alt="" />
        <SelectEntity
          v-if="user.role === 'admin'"
          :company="companyName"
          :entities="entities"
        ></SelectEntity>
        <span v-else class="hidden lg:block text-4xl">{{ companyName }}</span>
      </div>
      <div class="flex lg:hidden">
        <button type="button" class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700" @click="mobileMenuOpen = true">
          <!-- <span class="sr-only">Open main menu</span> -->
          <!-- <Bars3Icon class="h-6 w-6" aria-hidden="true" /> -->
          <img src="@/assets/default-avatar.png" alt="Profile" class="w-8 h-8 rounded-full" aria-hidden="true">
        </button>
      </div>
      <div class="hidden lg:flex lg:gap-x-12 items-center">
        <!-- <a v-for="item in navigation" :key="item.name" :href="item.href" class="text-sm font-semibold leading-6 text-gray-900">{{ item.name }}</a> -->
        <SimpleDropdown
          v-for="item in navigation" :key="item.name"
          :buttonString="item.name"
          :dropdownItems="item.list"
          :addIcon="false"
        ></SimpleDropdown>
        <DropdownProfile
          :username="user.firstName"
          :userEmail="user.email"
        ></DropdownProfile>
      </div>
    </nav>
    <Dialog class="lg:hidden" @close="mobileMenuOpen = false" :open="mobileMenuOpen">
      <div class="fixed inset-0 z-10"></div>
      <DialogPanel class="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
        <div class="flex items-center justify-between">
          <div class="flex flex-col items-center text-center -my-2">
            <img src="@/assets/default-avatar.png" alt="Profile" class="w-8 h-8 rounded-full">
            <p class="text-sm">{{ user.firstName }}</p>
          </div>
          <button type="button" class="-m-2.5 rounded-md p-2.5 text-gray-700" @click="mobileMenuOpen = false">
            <span class="sr-only">Close menu</span>
            <XMarkIcon class="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div class="mt-6 flow-root">
          <div class="-my-6 divide-y divide-gray-500/10">
            <div class="space-y-2 py-6">
              <a v-for="item in navigation" :key="item.name" :href="item.href" class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">{{ item.name }}</a>
            </div>
            <div class="py-6">
              <a href="#" class="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">Sign out</a>
            </div>
          </div>
        </div>
      </DialogPanel>
    </Dialog>
  </header>
</template>
  
<script>
  import { useStore } from 'vuex';
  import {  ref } from 'vue';
  import { router } from '@/router/index';
  import { auth } from '@/firebase';

  import { Dialog, DialogPanel } from '@headlessui/vue';
  import { XMarkIcon } from '@heroicons/vue/24/outline';
  import DropdownProfile from '@/components/dropdowns/Profile';
  import SelectEntity from '@/components/dropdowns/SelectEntity';
  import SimpleDropdown from '@/components/dropdowns/SimpleDropdown';


  export default {
    name: 'DefaultHeader',
    components: {
      Dialog,
      DialogPanel,
      XMarkIcon,
      DropdownProfile,
      SelectEntity,
      SimpleDropdown,
    },
    setup() {
      // Set up reference variables
      const companyLogo = ref('');
      const companyName = ref('');
      const entities = ref({});
      const mobileMenuOpen = ref(false);

      const navigation = [
        // { name: 'Product', list: [{name: 'test', href: '#'}] },
        // { name: 'Features', href: '#' },
        // { name: 'Marketplace', href: '#' },
        // { name: 'Company', href: '#' },
      ]

      // Grab stored values
      const store = useStore();
      const user = store.state.user;

      // Set initial values
      if (user.entityData && user.entityData[user.entityViewing[0]]) {
        if (user.entityViewing.length > 1) {
          companyName.value = 'All';
          companyLogo.value = null;
          entities.value = user.entityData;
        } else {
          companyName.value = user.entityData[user.entityViewing[0]].name || 'Meshportal';
          companyLogo.value = user.entityData[user.entityViewing[0]].logo || null;
          entities.value = user.entityData;
        }
      }

      /**
       * Signs out the user
       */
      const signOut = async () => {
        await auth.signOut();
        store.dispatch('setUser', null);
        router.push({ name: 'Login' });
      };

      return {
        companyName,
        companyLogo,
        entities,
        user,
        mobileMenuOpen,
        navigation,
        signOut,
      }
    }
  };
</script>
  
<style>

</style>
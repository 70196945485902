<template>
    <h1 class="text-xl my-6 underline font-bold">My Company</h1>
    <div class="flex items-center">
        <h3 class="font-bold">Name:</h3>
        <p class=" ml-6">{{ company.name }}</p>
    </div>
    <div class="flex items-center">
        <h3 class="font-bold">Status:</h3>
        <p class=" ml-5">{{ company.status }}</p>
    </div>
    <div>
        <h3 class="font-bold">Logo:</h3>
        <img :src=company.logo alt="logo" class=" w-52 mb-8">
    </div>
    <div>
        <h3 class="font-bold">Icon:</h3>
        <img :src=company.icon alt="logo" class=" w-8 mb-8">
    </div>
    <div class="flex-grow overflow-y-auto">
        <div class="mb-12 pr-12">
            <div class=" flex items-center justify-between">
                <h3 class="font-bold">Users</h3>
                <div>
                    <button @click="createUserModal = true" class="my-3 bg-indigo-600 shadow-gray-500 shadow-lg hover:bg-indigo-500 rounded-md p-5 py-2 text-xs font-semibold text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">+ add user</button>
                </div>
            </div>
            <table class="w-full table-fixed border-collapse">
                <thead>
                    <tr class=" bg-sky-600 h-12">
                        <th class="w-8 text-left pl-2"><input type="checkbox"></th>
                        <th class="text-left">Name</th>
                        <th class="text-left">Email</th>
                        <th class="text-left">Role</th>
                        <th class="text-left">Status</th>
                        <th class="text-left w-24">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="companyUser in companyUsers" :key="companyUser.uid" class=" bg-gray-300 hover:bg-gray-200 h-12">
                        <td class="w-8 pl-2 overflow-hidden overflow-ellipsis whitespace-nowrap"><input type="checkbox"></td>
                        <td class="overflow-hidden overflow-ellipsis whitespace-nowrap">{{ companyUser.firstName }} {{ companyUser.lastName }}</td>
                        <td class="overflow-hidden overflow-ellipsis whitespace-nowrap">{{ companyUser.email }}</td>
                        <td class="overflow-hidden overflow-ellipsis whitespace-nowrap">
                            <select id="role" v-model="companyUser.role" class="bg-transparent border-none" @change="(event) => editUser(companyUser.uid, 'role', event)">
                                <option v-for="role in roleOptions" :key="role" :value="role">{{ role }}</option>
                            </select>
                        </td>
                        <!-- <td class="overflow-hidden overflow-ellipsis whitespace-nowrap">{{ companyUser.role }}</td> -->
                        <td class="overflow-hidden overflow-ellipsis whitespace-nowrap">
                            <select id="status" v-model="companyUser.status" class="bg-transparent border-none" @change="(event) => editUser(companyUser.uid, 'status', event)">
                                <option v-for="status in statusOptions" :key="status" :value="status">{{ status }}</option>
                            </select>
                        </td>
                        <!-- <td class="overflow-hidden overflow-ellipsis whitespace-nowrap">{{ companyUser.status }}</td> -->
                        <td class="overflow-visible overflow-ellipsis whitespace-nowrap w-24">
                            <div class="flex relative ">
                                <img
                                    src="@/assets/three_dot_menu_verticle.png"
                                    alt="actions"
                                    class="h-5 w-5 cursor-pointer"
                                    @click="toggleUserMenu(companyUser.uid)"
                                />
                                <div v-if="userMenu === companyUser.uid" class=" absolute top-0 right-0 bg-white border-gray-300 shadow z-50">
                                    <ul class=" list-none m-0 p-0">
                                        <!-- <li @click="editUser(companyUser.uid), userMenu = null" class="p-3 cursor-pointer hover:bg-[#f0f0f0]">Edit</li> -->
                                        <li @click="deleteUser(companyUser.uid), userMenu = null" class="p-3 cursor-pointer hover:bg-[#f0f0f0]">Delete</li>
                                    </ul>
                                </div>
                                <LoadingAnimation1 v-if="loading === companyUser.uid" class="h-5 w-5"></LoadingAnimation1>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <CreateUser v-if="createUserModal" @close="createUserModal = false" @submitForm="createUser"></CreateUser>
</template>
    
<script>
    import { ref, onMounted } from "vue";
    import { useStore } from 'vuex';
    import { db, storage } from '@/firebase';
    import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
    import { ref as firebaseRef, uploadBytes, getDownloadURL } from 'firebase/storage';

    import CreateUser from "@/components/modals/CreateUser.vue";
    import LoadingAnimation1 from '@/components/loadingIcons/LoadingAnimation1.vue';

    export default {
        name: 'SettingsCompany',
        components: {
            CreateUser,
            LoadingAnimation1
        },
        props: {
        // provided values
        },
        setup() {
            const company = ref({});
            const companyUsers = ref([]);
            const userMenu = ref(null);
            const createUserModal = ref(false);
            const loading = ref(null);
            const roleOptions = ref([
                "viewer",
                "admin"
            ]);
            const statusOptions = ref([
                "active",
                "disabled"
            ]);

            // Grab stored values
            const store = useStore();
            const user = store.state.user;

            onMounted(async() => {
                try {
                    const companyQry = doc(db, "entities", user.entity);
                    const companySnapshot = await getDoc(companyQry);
                    company.value = companySnapshot.data();

                    const qry = query(collection(db, "users"), where("entity", "==", user.entity));
                    const querySnapshot = await getDocs(qry);
                    querySnapshot.forEach((doc) => {
                        const data = doc.data();
                        data['uid'] = doc.id;
                        companyUsers.value.push(data)
                    });
                } catch (error) {
                    console.log(error)
                }
            });
            
            const toggleUserMenu = (uid) => {
                if (userMenu.value === uid) {
                    userMenu.value = null;
                } else {
                    userMenu.value = uid;
                }
            };

            const uploadImage = async (folder, file) => {
                if (!file) return "";
                let imageURL = null;
                const storageRef = firebaseRef(storage, `${folder}/${file.name}`);
                await uploadBytes(storageRef, file).then(() => {
                    return getDownloadURL(storageRef);
                }).then((url) => {
                    imageURL = url;
                });
                return imageURL;
            };

            const createUser = async (data) => {
                let responseData;
                const entityID = user.entity;
                
                const body = {
                    uid: user.uid,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    email: data.email,
                    role: data.role,
                    entity: entityID,
                    company: data.company,
                    timeFormat: data.timeFormat || "24Hour",
                    permissions: data.permissions || {},
                    units: data.units || [],
                    profilePic: await uploadImage('profile', data.profilePic) || "",
                    status: 'active'
                }

                try {
                    const response = await fetch('https://us-central1-mesh-db-stg.cloudfunctions.net/user_requests', {
                        method: 'POST',
                        headers: {
                            'content-Type': 'application/json'
                        },
                        body: JSON.stringify(body)
                    });
                    responseData = await response.json();
                } catch (error) {
                    console.log(error);
                }

                createUserModal.value = false;
                if (responseData.code === 200) {
                    body.uid = responseData.details.newUserUID;
                    companyUsers.value.push(body);
                }
                setTimeout(() => {
                    if (responseData.code === 200) {
                        alert("Results:" + responseData.message);
                    } else {
                        try {
                            alert(responseData.message);
                        } catch (error) {
                            alert("Something went wrong when creating the user");
                        }
                    }
                }, 100);
            };

            const editUser = async (userUID, editType, event) => {
                userMenu.value = null;
                loading.value = userUID;
                console.log(editType, event);
                let responseData;

                const body = {
                    uid: user.uid,
                    userUID: userUID
                }

                try {
                    const response = await fetch('https://us-central1-mesh-db-stg.cloudfunctions.net/user_requests', {
                        method: 'PUT',
                        headers: {
                            'content-Type': 'application/json'
                        },
                        body: JSON.stringify(body)
                    });
                    responseData = await response.json();
                } catch (error) {
                    console.log(error);
                }

                loading.value = null;
                if (responseData.code === 200) {
                    companyUsers.value.filter(map => map.uid === userUID);
                }
                setTimeout(() => {
                    if (responseData.code === 200) {
                        alert("Results:" + responseData.message);
                    } else {
                        try {
                            alert(responseData.message);
                        } catch (error) {
                            alert("Something went wrong when deleting the user");
                        }
                    }
                }, 100);
            };

            const deleteUser = async (userUID) => {
                if (userUID === user.uid) {
                    alert("cant delete self");
                    return;
                }
                userMenu.value = null;
                loading.value = userUID;
                let responseData;
                const body = {
                    uid: user.uid,
                    userUID: [userUID]
                }

                try {
                    const response = await fetch('https://us-central1-mesh-db-stg.cloudfunctions.net/user_requests', {
                        method: 'DELETE',
                        headers: {
                            'content-Type': 'application/json'
                        },
                        body: JSON.stringify(body)
                    });
                    responseData = await response.json();
                } catch (error) {
                    console.log(error);
                }

                loading.value = null;
                if (responseData.code === 200) {
                    companyUsers.value = companyUsers.value.filter(map => map.uid !== userUID);
                }
                setTimeout(() => {
                    if (responseData.code === 200) {
                        alert("Results:" + responseData.message);
                    } else {
                        try {
                            alert(responseData.message);
                        } catch (error) {
                            alert("Something went wrong when deleting the user");
                        }
                    }
                }, 100);
            };

            return {
                company,
                companyUsers,
                userMenu,
                createUserModal,
                loading,
                roleOptions,
                statusOptions,
                toggleUserMenu,
                createUser,
                editUser,
                deleteUser
            };
        }
    }
</script>

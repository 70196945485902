<template>
  <DefaultLayout
  :pageTitle="pageTitle"
  :pageSubtitle="pageSubtitle"
  :subpageLinks="subpageLinks"
  >
      <div class="unit-events-body">
        <div v-for="(table, index) in tables" :key="index" style="width: 99%; margin-top: 20px; margin-bottom: 40px;">
            <EventsTable v-if="table.type === 'run and charge'"
                :dataID="dataID"
                :source="table.source"
                :timeFormat="timeFormat"
            ></EventsTable>
        </div>
      </div>
      <button @click="createEvent = true">+ New Table</button>
      <CreateEvent v-if="createEvent" @close="createEvent = false" @submitForm="createEventTable"
        :dataID="dataID">
      </CreateEvent>
  </DefaultLayout>
</template>
  
<script>
  import { useStore } from 'vuex';
  import { onMounted, ref } from 'vue';
  import { router } from '@/router';
  import { doc, getDoc } from 'firebase/firestore';
  import { db } from '@/firebase';
  import { utcToLocal, timeSimplified } from '@/tools/TimeFunctions';

  import DefaultLayout from '@/layouts/Layout.vue';
  import EventsTable from '@/components/tables/EventsTable.vue';
  import CreateEvent from '@/components/modals/CreateEvent.vue';
  
  export default {
      name: 'UnitDashboardEvents',
      components: {
          DefaultLayout,
          EventsTable,
          CreateEvent
      },
      setup() {
          // Set up reference variables.
          const pageTitle = ref('');
          const pageSubtitle = ref('');
          const subpageLinks = ref(new Map());
          const tables = ref(new Object());
          const createEvent = ref(false);

          // Set up all other variables
          const store = useStore();
          const timeFormat = store.state.user.timeFormat;
          const dataID = router.currentRoute.value.params.dataID;
          let unitDetails;

          onMounted(async () => {
              await getUnitDetails();
              getTemplate();
          });

          /**
           * Grabs the unit details and sets up the page title
           */
           const getUnitDetails = async() => {
              
              // Attempt the data document using the data ID
              try {
                  const docRef = doc(db, "data", dataID);
                  const docSnap = await getDoc(docRef);

                  if (docSnap.exists()) {
                      unitDetails = docSnap.data();
                      if (unitDetails.friendlyName !== "") {
                          pageTitle.value = unitDetails.friendlyName;
                          pageSubtitle.value = `<p>
                              <span style="font-weight: bold">ID: </span>
                              ${dataID}
                              <span style="font-weight: bold; margin-left: 20px;"> Last Active: </span>
                              ${timeSimplified(utcToLocal(unitDetails.lastActivityUTC, timeFormat))}
                              </p>`;
                      
                      } else {
                          pageTitle.value = dataID;
                          pageSubtitle.value = `<p>
                              <span style="font-weight: bold;">Last Active: </span>
                              ${timeSimplified(utcToLocal(unitDetails.lastActivityUTC, timeFormat))}
                              </p>`;
                      }

                  } else {
                      pageTitle.value = dataID + " (NON-EXISTANT)";
                  }
              } catch(error) {
                  console.log(error)
                  pageTitle.value = dataID;
                  pageSubtitle.value = "Faild to retrieve data";
              }
          };

          /**
           * Grabs the template for the unit and adds additional subpage links
           */
            const getTemplate = async() => {

                // The template is not stored so grab it from the database.
                try {
                    const docRef = doc(db, "templates", unitDetails.defaultTemplate);
                    const docSnap = await getDoc(docRef);

                    if (docSnap.exists()) {
                        // Grab the document data and store it
                        const templateData = docSnap.data();

                        // Grab the statistics.
                        tables.value = templateData.events.tables;

                        // Add any other additional subpage links
                        subpageLinks.value.set('Overview', [`/dashboard/${dataID}`]);

                        if (templateData.timeSeries) {
                            subpageLinks.value.set('Time Series', [`/dashboard/${dataID}/time-series`]);
                        }

                        if (templateData.events) {
                            subpageLinks.value.set('Events', [null, 'inactive']);
                        }

                    }
                } catch(error) {
                    console.log(error);
                }          
            };

          return {
              pageTitle,
              pageSubtitle,
              subpageLinks,
              dataID,
              timeFormat,
              tables,
              createEvent,
          }
      }
  };
</script>
  
<style>

  .unit-events-body {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-around;
  }

</style>
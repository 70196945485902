<template>
  <aside class="sidebar">

    <!-- Sidebar content starting from the top -->
    <div class="sidebar-content">
      <RouterLink to="/dashboard" class="sidebar-icon sidebar-home">
        <img src="../assets/home_white.png" alt="Home">
        <span class="sidebar-text">Home</span>
      </RouterLink>
      <RouterLink to="/dashboard" class="sidebar-icon">
        <img src="../assets/gauge_white.png" alt="Gauge">
        <span class="sidebar-text">Dashboard</span>
      </RouterLink>
    </div>
  
    <!-- Settings icon at the bottom -->
    <div class="sidebar-footer" style="margin-bottom: 10px;">
      <RouterLink v-if="isAdmin" to="/settings" class="sidebar-icon">
          <img src="../assets/gear_white.png" alt="Gear">
          <span class="sidebar-text">Settings</span>
      </RouterLink>
    </div>
  </aside>
</template>
  
<script>
  import { useStore } from 'vuex';
  import { ref } from 'vue';

  export default {
    name: 'DefaultSidebar',
    setup() {
      const isAdmin = ref(false);
      // Grab stored values
      const store = useStore();
      const user = store.state.user;

      if (user.role === 'admin') {
        isAdmin.value = true;
      }

      return {
        isAdmin
      }

    }
  };
</script>
  
<style>
  .sidebar {
    position: absolute;
    /* top: 80px;
    left: 10px; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100vh - 100px);
    width: 60px;
    background-color: #045ea3;
    margin-top: 10px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    z-index: 1000;
    transition: width 0.3s ease-in-out;
    overflow: hidden;
  }

  .sidebar:hover {
    width: 150px;
  }

  .sidebar-icon {
    display: flex;
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
    overflow: hidden;
    width: 100%;
    padding-left: 10px;
    margin-top: 10px;
  }

  .sidebar-icon:hover {
    background-color: #004477;
  }
  
  .sidebar-home {
    border-bottom-style: solid;
    border-bottom-color: white;
    padding-bottom: 10px;
  }

  .sidebar-icon img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    transition: margin 0.3s ease-in-out;
  }

  .sidebar-text {
    opacity: 0;
    margin-left: 10px;
    white-space: nowrap;
    transition: opacity 0.3s ease-in-out;
    color: white;
  }

  .sidebar:hover .sidebar-text {
    opacity: 1;
  }
</style>
<template>
  <div class="modal-overlay" @click.self="close">
    <div class="modal-content">
      <h2>Create New Event Table</h2>
      <br>
      <form @submit.prevent="handleSubmit">
        <label>
          Title:
          <br>
          <input v-model="title" type="text" required />
        </label>
        <br><br>
        <label>
          Source:
          <br>
          <select v-model="source" style="width: 100%;" required>
            <option v-for="subcollection in subcollections" :key="subcollection" :value="subcollection">{{ subcollection }}</option>
          </select>
        </label>
        <br><br>
        <label>
          <input v-model="startTime" type="checkbox" style="width: 17px;"/>
          Start Time
        </label>
        <br><br>
        <label>
          <input v-model="endTime" type="checkbox" style="width: 17px;"/>
          End Time
        </label>
        <br><br>
        <label>
          <input v-model="elapsedTime" type="checkbox" style="width: 17px;"/>
          Elapsed Time
        </label>
        <br><br>
        <div v-for="(column, index) in additionalColumns" :key="index">
          {{ `Column ${index + 1}` }}
          <br>
          <label>
            Name
            <input type="text" />
          </label>
          <br><br>
          <label>
            field:
            <br>
            <select v-model="source" style="width: 100%;" required>
              <option v-for="field in fields" :key="field" :value="field">{{ field }}</option>
            </select>
          </label>
          <br><br>
        </div>
        <button type="button" @click="addColumn">+ Add Column</button>
        <br><br>
        <div style="display: flex;">
          <button @click="close" style="margin-right: 20px; height: 30px;">Close</button>
          <button type="submit" :disabled="loading" style="margin-right: 10px; height: 30px;">Create</button>
          <LoadingAnimation1 v-if="loading"></LoadingAnimation1>
        </div>
      </form>
    </div>
    <div id="preview">

    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
// import { doc } from 'firebase/firestore';
// import { db } from '@/firebase';

import LoadingAnimation1 from '../loadingIcons/LoadingAnimation1.vue';

export default {
  name: 'CreateEvent',
  components: {
    LoadingAnimation1
  },
  props: {
    dataID: String,
  },
  setup(props, { emit }) {
    const title = ref(null);
    const source = ref(null);
    const startTime = ref(true);
    const endTime = ref(true);
    const elapsedTime = ref(true);
    const additionalColumns = ref([]);
    const loading = ref(false);
    const subcollections = ref([]);
    const fields = ref([]);

    //  onMounted(async () => {
    //   const docRef = doc(db, `data/${props.dataID}`);
    //   const collectionList = await docRef.listCollections();

    //   collectionList.forEach((subcollection) => {
    //     subcollection.push(subcollection);
    //   });
    // });

    const handleSubmit = () => {
      loading.value = true;
      const formData = {startTime: startTime.value, endTime: endTime.value, elapsedTime: elapsedTime.value, columns: additionalColumns.value};
      emit('submitForm', formData);
    };

    const addColumn = () => {
      additionalColumns.value.push({
        name: null,
        field: null,
      });
    };

    const close = () => {
      emit('close');
    };

    return {
      title,
      source,
      startTime, 
      endTime,
      elapsedTime,
      additionalColumns,
      loading,
      subcollections,
      fields,
      handleSubmit,
      addColumn,
      close,
    };
  }
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 300px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  
}

input {
  width: 100%;
}
</style>
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth, setPersistence, browserSessionPersistence } from "firebase/auth";
import { getFunctions} from 'firebase/functions';
import { getStorage } from 'firebase/storage'

// mesh-db
const firebaseConfig = {
  apiKey: "AIzaSyAdnZ7SDSCwfssmTuoztLkglh9w06vbPg8",
  authDomain: "mesh-db-stg.firebaseapp.com",
  projectId: "mesh-db-stg",
  storageBucket: "mesh-db-stg.appspot.com",
  messagingSenderId: "795807899206",
  appId: "1:795807899206:web:022aa712bd27a4d62a7447",
  measurementId: "G-QVEXZLBPVF"
};

// // dev-db
// const firebaseConfig = {
//   apiKey: "AIzaSyAA2DgfyTl6hgbAJlOXNw7X_ECjS235KVQ",
//   authDomain: "dev-db-stg.firebaseapp.com",
//   projectId: "dev-db-stg",
//   storageBucket: "dev-db-stg.appspot.com",
//   messagingSenderId: "88014250307",
//   appId: "1:88014250307:web:dcb69ce2472f0c8f3bcff6",
//   measurementId: "G-YK5HPVGSXM"
// };

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const functions = getFunctions(app)
const storage = getStorage(app)

setPersistence(auth, browserSessionPersistence);

export { db, auth, functions, storage };

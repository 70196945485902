import { createRouter, createWebHistory } from 'vue-router';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '@/firebase.js'

import LoginMain from '@/pages/Login.vue';
import HomeMain from '@/pages/Home.vue';
import ResetPassword from '@/pages/ResetPassword.vue';
import MainDashboard from '@/pages/Dashboard.vue';
import PortalSettings from '@/pages/Settings.vue';
import UnitDashboard from '@/pages/UnitDashboard.vue';
import UnitDashboardTimeseries from '@/pages/UnitDashboardTimeseries.vue';
import UnitDashboardEvents from '@/pages/UnitDashboardEvents.vue';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/login',
      name: 'Login',
      component: LoginMain
    },
    {
      path: '/reset-password',
      name: 'ResetPassword',
      component: ResetPassword
    },
    {
      path: '/',
      name: 'Home',
      component: HomeMain,
      meta: { requiresAuth: true }
    },
    {
      path: '/dashboard',
      name: 'Dashboard',
      component: MainDashboard,
      meta: { requiresAuth: true }
    },
    {
      path: '/settings',
      name: 'Settings',
      component: PortalSettings,
      meta: { requiresAuth: true }
    },
    {
      path: '/dashboard/:dataID',
      name: 'UnitDashboad',
      component: UnitDashboard,
      meta: { requiresAuth: true }
    },
    {
      path: '/dashboard/:dataID/time-series',
      name: 'UnitDashboadTimeseries',
      component: UnitDashboardTimeseries,
      meta: { requiresAuth: true }
    },
    {
      path: '/dashboard/:dataID/events',
      name: 'UnitDashboadEvents',
      component: UnitDashboardEvents,
      meta: { requiresAuth: true }
    },
    // other routes...
  ]
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  
  onAuthStateChanged(auth, (user) => {
    if (requiresAuth && !user) {
      next({ name: 'Login' });
    } else {
      next();
    }
  });
});

export { router };
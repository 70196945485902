<template>
    <div class="time-series">
        <VuePlotly
            :key="key"
            :data="data"
            :layout="layout"
            :config="config"
        ></VuePlotly>
    </div>
</template>
  
<script>
    import { ref, onMounted } from 'vue';
    import { VuePlotly } from 'vue3-plotly';
    // import VuePlotly from 'vue-plotly'
    import { getPastTimestamp, utcToLocal } from '@/tools/TimeFunctions';
    import { collection, query, orderBy, where, onSnapshot, limit, getDocs} from 'firebase/firestore';
    import { db } from '@/firebase';
  
    export default {
        name: 'TimeSeries',
        components: {
            VuePlotly
        },
        props: {
            template: Object,
            dataID: String,
        },
        setup(props) {
            const key = ref(0);
            const data = ref([]);
            const config = ref({displaylogo: false})
            const layout = ref({
                // plot_bgcolor: '#eeeeee',
                showlegend: true,
                legend: {
                    x: 0,
                    y: -0.8,
                    orientation: 'h',
                },
                title: {
                    text: props.template.title,
                    font: {
                        size: 25,
                    },
                },
                annotations: [
                    {
                        text: 'Broken',
                        xref: 'paper',
                        yref: 'paper',
                        x: 0.0,
                        y: -1.0,
                        showarrow: false,
                        xanchor: 'left'
                    }
                ],
                xaxis: {
                    autorange: true,
                    mirror: true,
                    ticks: 'outside',
                    showline: true,
                    type: 'date',
                    rangeselector: {
                        // bgcolor: 'blue',
                        // font: { color: 'white'},
                        buttons: [
                            {
                                count: 24,
                                label: '24hr',
                                step: 'hour',
                                stepmode: 'backward',
                            },
                            {
                                count: 12,
                                label: '12hr',
                                step: 'hour',
                                stepmode: 'backward'
                            },
                            {
                                count: 30,
                                label: '30min',
                                step: 'minute',
                                stepmode: 'backward'
                            },
                            {
                                count: 10,
                                label: '10min',
                                step: 'minute',
                                stepmode: 'backward'
                            },
                        ]
                    },
                    rangeslider: {
                        visible: true,
                        borderwidth: 1,
                        bordercolor: 'black'
                    },
                },
                yaxis: {
                    autorange: true,
                    mirror: true,
                    ticks: 'outside',
                    showline: true,
                    type: 'linear'
                },
            });

            let firstRun = true;

            onMounted(() => {
                grabData();
            });

            const grabData = () => {
                for (let subcollection in props.template.specifications) {
                    for (let obj of props.template.specifications[subcollection]) {
                        data.value.push({
                            type: "scatter",
                            mode: "lines",
                            name: obj.friendlyName,
                            x: [],
                            y: [],
                            line: {
                                color: obj.color
                            },
                            hovertemplate: `%{x} = %{y} ${obj.unit}`,
                        });
                        // layout.value.annotations.push(
                        //     {
                        //         x: null,
                        //         y: null,
                        //         xref: 'x',
                        //         yref: 'y',
                        //         text: ``,
                        //         showarrow: true,
                        //         // arrowhead: 0,
                        //         // ax: 0,
                        //         // ay: -40
                        //     }
                        // )
                    }
                    getLastUpdate(subcollection);
                    setSnapshots(subcollection);
                }
            };

            const getLastUpdate = async(subcollection) => {
                const subcollectionRef = collection(db, 'data', props.dataID, subcollection);
                const q = query(subcollectionRef, orderBy("messageUTC", "desc"), limit(1));

                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                    layout.value.annotations[0].text = `Last Update: ${utcToLocal(doc.data().messageUTC, '12hour')}`;
                });
                key.value += 1;
            };

            /**
             * Grab data from a subcollection in the database
             * @param   {String}    subcollection The subcollection the data is located
             */
            const setSnapshots = (subcollection) => {
                const subcollectionRef = collection(db, 'data', props.dataID, subcollection);
                const q = query(subcollectionRef, orderBy('messageUTC'), where('messageUTC', '>=', getPastTimestamp(24)));
                onSnapshot(q, (snapshot) => {
                    // index = 0;
                    snapshot.docChanges().forEach((change) => {
                        const docData = change.doc.data();
                        for (let obj of props.template.specifications[subcollection]) {
                            const newTimeStamp = docData.messageUTC;
                            layout.value.annotations[0].text = `Last Update: ${utcToLocal(docData.messageUTC, '12hour')}`;
                            const foundData = data.value.find(item => item.name === obj.friendlyName);
                            if (firstRun) {
                                foundData.x.push(utcToLocal(docData.messageUTC, '24hour'));
                                foundData.y.push(docData[obj.field]);
                            } else{
                                const insertIndex = foundData.x.findIndex(timeStamp => new Date(timeStamp) > newTimeStamp);
                                if (insertIndex === -1) {
                                    foundData.x.push(utcToLocal(docData.messageUTC, '24hour'));
                                    foundData.y.push(docData[obj.field]);

                                } else {
                                    foundData.x.splice(insertIndex, utcToLocal(docData.messageUTC, '24hour'));
                                    foundData.y.splice(insertIndex, docData[obj.field]);
                                }
                            }
                            // layout.value.annotations[index][x] = 
                            // index += 1;
                            if (key.value >= 1 && foundData.x[0] < getPastTimestamp(24)) {
                                foundData.x.shift();
                                foundData.y.shift();
                            }
                        }
                    });

                    key.value += 1;
                    firstRun = false;
                });
            };
        
            return {
                data,
                layout,
                key,
                config,
            };
        },
    };
</script>

<style>
    .time-series {
        background-color: white;
        border-radius: 10px;
        padding: 20px;
        height: fit-content;
        margin-bottom: 20px;
        width: 500px;
    }
</style>
<template>
    <div class="flex items-center">
        <h1 class="text-xl my-6 underline font-bold mr-5">Sub-Entities</h1>
        <button @click="modal = 'createEntityModal'" class="bg-indigo-600 shadow-gray-500 shadow-lg hover:bg-indigo-500 rounded-md p-5 py-2 text-xs font-semibold text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">+ add entity</button>
    </div>
    <div class=" flex-grow overflow-y-auto">
        <div v-for="(entityData, entityID) in entities" :key="entityID" class="mb-12 pr-12">
            <div class="flex items-center justify-between">
                <h3 class="font-bold">{{entityData.name}}</h3>
                <div>
                    <button @click="modal = 'createUserModal', selectedEntity = entityID" class="my-3 bg-indigo-600 shadow-gray-500 shadow-lg hover:bg-indigo-500 rounded-md p-5 py-2 text-xs font-semibold text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">+ add user</button>
                </div>
            </div>
            <table class="w-full table-fixed border-collapse">
                <thead>
                    <tr class=" bg-sky-600 h-12">
                        <th class="w-8 text-left pl-2"><input type="checkbox"></th>
                        <th class="text-left">Users</th>
                        <th class="text-left">Email</th>
                        <th class="text-left">Role</th>
                        <th class="text-left">Status</th>
                        <th class="text-left w-24">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="entityUser in entityData.users" :key="entityUser.uid" class=" bg-gray-300 hover:bg-gray-200 h-12">
                        <td class="w-8 pl-2 overflow-hidden overflow-ellipsis whitespace-nowrap"><input type="checkbox"></td>
                        <td class="overflow-hidden overflow-ellipsis whitespace-nowrap">{{ entityUser.firstName }} {{ entityUser.lastName }}</td>
                        <td class="overflow-hidden overflow-ellipsis whitespace-nowrap">{{ entityUser.email }}</td>
                        <td class="overflow-hidden overflow-ellipsis whitespace-nowrap">
                            <select id="role" v-model="entityUser.role" class="bg-transparent border-none">
                                <option v-for="role in roleOptions" :key="role" :value="role">{{ role }}</option>
                            </select>
                        </td>
                        <!-- <td class="overflow-hidden overflow-ellipsis whitespace-nowrap">{{ entityUser.role }}</td> -->
                        <td class="overflow-hidden overflow-ellipsis whitespace-nowrap">
                            <select id="status" v-model="entityUser.status" class="bg-transparent border-none">
                                <option v-for="status in statusOptions" :key="status" :value="status">{{ status }}</option>
                            </select>
                        </td>
                        <!-- <td class="overflow-hidden overflow-ellipsis whitespace-nowrap">{{ entityUser.status }}</td> -->
                        <td class="overflow-visible overflow-ellipsis whitespace-nowrap w-24">
                            <div class="flex relative ">
                                <img
                                    src="@/assets/three_dot_menu_verticle.png"
                                    alt="actions"
                                    class="h-5 w-5 cursor-pointer"
                                    @click="toggleUserMenu(entityUser.uid)"
                                />
                                <div v-if="userMenu === entityUser.uid" class=" absolute top-0 right-0 bg-white border-gray-300 shadow z-50">
                                    <ul class=" list-none m-0 p-0">
                                        <li @click="modal = 'editUserModal', userMenu = null, selectedEntity = entityID, selectedUser = entityUser" class="p-3 cursor-pointer hover:bg-[#f0f0f0]">Edit</li>
                                        <li @click="deleteUser(entityUser.uid), userMenu = null, selectedEntity = entityID" class="p-3 cursor-pointer hover:bg-[#f0f0f0]">Delete</li>
                                    </ul>
                                </div>
                                <LoadingAnimation1 v-if="loading === entityUser.uid" class="h-5 w-5"></LoadingAnimation1>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <CreateEntity v-if="modal === 'createEntityModal'" @close="modal = null" @submitForm="createEntity"></CreateEntity>
    <CreateUser v-if="modal === 'createUserModal'" @close="modal = null" @submitForm="createUser"></CreateUser>
    <EditUser v-if="modal === 'editUserModal'" @close="modal = null" @submitForm="editUser"
        :entities="entities"
        :user="selectedUser"
    ></EditUser>
</template>
    
<script>
    import { ref, onMounted } from "vue";
    import { useStore } from 'vuex';
    import { db, storage } from '@/firebase';
    import { collection, query, where, getDocs } from 'firebase/firestore';
    import { ref as firebaseRef, uploadBytes, getDownloadURL } from 'firebase/storage';

    import CreateEntity from "@/components/modals/CreateEntity.vue";
    import CreateUser from "@/components/modals/CreateUser.vue";
    import EditUser from '@/components/modals/EditUser.vue';
    import LoadingAnimation1 from '@/components/loadingIcons/LoadingAnimation1.vue';

    export default {
        name: 'SettingsEntities',
        components: {
            CreateEntity,
            CreateUser,
            EditUser,
            LoadingAnimation1
        },
        props: {
        // provided values
        },
        setup() {
            const entities = ref({});
            const selectedUser = ref('');
            const selectedEntity = ref('');
            const userMenu = ref(null);
            const modal = ref(null);
            const loading = ref(null);
            const roleOptions = ref([
                "viewer",
                "admin"
            ]);
            const statusOptions = ref([
                "active",
                "disabled"
            ]);

            // Grab stored values
            const store = useStore();
            const user = store.state.user;

            onMounted(async() => {
                try {
                    // Grab the sub-entities.
                    for (let entity in user.entityData) {
                        if (entity != user.entity) {
                            entities.value[entity] = {name: user.entityData[entity].name, users:[]};

                            const qry = query(collection(db, "users"), where("entity", "==", entity));
                            const querySnapshot = await getDocs(qry);
                            querySnapshot.forEach((doc) => {
                                const data = doc.data();
                                data['uid'] = doc.id;
                                entities.value[entity].users.push(data);
                            });
                        }
                    }
                } catch (error) {
                    console.log(error)
                }
            });
            
            const toggleUserMenu = (uid) => {
                if (userMenu.value === uid) {
                    userMenu.value = null;
                } else {
                    userMenu.value = uid;
                }
            };

            const uploadImage = async (folder, file) => {
                if (!file) return "";
                let imageURL = null;
                const storageRef = firebaseRef(storage, `${folder}/${file.name}`);
                await uploadBytes(storageRef, file).then(() => {
                    return getDownloadURL(storageRef);
                }).then((url) => {
                    imageURL = url;
                });
                return imageURL;
            };

            const createEntity = async (data) => {
                let responseData;
                const body = {
                    uid: store.state.user.uid,
                    name: data.name,
                    logo: await uploadImage('logos', data.logo) || "",
                    icon: await uploadImage('icons', data.icon) || "",
                    url: data.url
                }

                try {
                    const response = await fetch('https://us-central1-mesh-db-stg.cloudfunctions.net/entity_requests', {
                        method: 'POST',
                        headers: {
                            'content-Type': 'application/json'
                        },
                        body: JSON.stringify(body)
                    });
                    responseData = await response.json();
                } catch (error) {
                    console.log(error);
                }

                modal.value = null;
                setTimeout(() => {
                    if (responseData.code === 200) {
                        alert("Results:" + responseData.message);
                        user.entityData[responseData.details.entityId] = body;
                        store.dispatch('setUser', user);

                        entities.value[responseData.details.entityId] = {name:body.name, users:[]};
                        return responseData.details.entityId;
                    } else {
                        try {
                            alert(responseData.message);
                        } catch (error) {
                            alert("Something went wrong when creating the entity");
                        }
                    }
                }, 100);
            };

            const createUser = async (data) => {
                let responseData;
        
                const body = {
                    uid: user.uid,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    email: data.email,
                    role: data.role,
                    entity: selectedEntity.value,
                    company: data.company,
                    timeFormat: data.timeFormat || "24Hour",
                    permissions: data.permissions || {},
                    units: data.units || [],
                    profilePic: await uploadImage('profile', data.profilePic) || "",
                    status: 'active'
                }

                try {
                    const response = await fetch('https://us-central1-mesh-db-stg.cloudfunctions.net/user_requests', {
                        method: 'POST',
                        headers: {
                            'content-Type': 'application/json'
                        },
                        body: JSON.stringify(body)
                    });
                    responseData = await response.json();
                } catch (error) {
                    console.log(error);
                }

                modal.value = null;
                if (responseData.code === 200) {
                    body.uid = responseData.details.newUserUID;
                    entities.value[selectedEntity.value].users.push(body);
                }
                setTimeout(() => {
                    if (responseData.code === 200) {
                        alert("Results:" + responseData.message);
                    } else {
                        try {
                            alert(responseData.message);
                        } catch (error) {
                            alert("Something went wrong when creating the user");
                        }
                    }
                }, 100);
            };

            const editUser = async (userData, entityData) => {
                userMenu.value = null;
                // loading.value = selectedUser.value.uid;
                let responseData;

                const body = {
                    uid: user.uid,
                    userUID: [selectedUser.value.uid],
                    firstName: userData.firstName,
                    lastName: userData.lastName,
                    email: userData.email,
                    role: userData.role,
                    entity: userData.entity,
                    company: userData.company,
                    timeFormat: userData.timeFormat || "24Hour",
                    permissions: userData.permissions || {},
                    units: userData.units || [],
                    profilePic: await uploadImage('profile', userData.profilePic) || "",
                    status: 'active'
                }

                let entityID;
                if (Object.keys(entityData).length !== 0) {
                    entityID = await createEntity(entityData);
                    body.entity = entityID;
                    body.company = entities.value[entityID].name;
                }

                try {
                    const response = await fetch('https://us-central1-mesh-db-stg.cloudfunctions.net/user_requests', {
                        method: 'PUT',
                        headers: {
                            'content-Type': 'application/json'
                        },
                        body: JSON.stringify(body)
                    });
                    responseData = await response.json();
                } catch (error) {
                    console.log(error);
                }

                // loading.value = null;
                modal.value = null;
                if (responseData.code === 200) {
                    body.uid = body.userUID;
                    delete body.userUID;
                    const index = entities.value[selectedEntity.value].users.findIndex(map => map.uid === selectedUser.value.uid);
                    if (entities.value[selectedEntity.value].users[index].entity !== body.entity) {
                        entities.value[selectedEntity.value].users.splice(index, 1);
                        entities.value[body.entity].users.push(body)
                    }
                    else {
                        entities.value[selectedEntity.value].users[index] = body;
                    }
                    // entities.value[selectedEntity.value].users = entities.value[selectedEntity.value].users.filter(map => map.uid !== selectedUser.value.uid);
                }
                setTimeout(() => {
                    if (responseData.code === 200) {
                        alert("Results:" + responseData.message);
                    } else {
                        console.log(responseData);
                        try {
                            alert(responseData.message);
                        } catch (error) {
                            alert("Something went wrong when deleting the user");
                        }
                    }
                }, 100);
            };

            const deleteUser = async (userUID) => {
                if (userUID === user.uid) {
                    alert("cant delete self");
                    return;
                }
                userMenu.value = null;
                loading.value = userUID;
                let responseData;
                const body = {
                    uid: user.uid,
                    userUID: [userUID]
                }

                try {
                    const response = await fetch('https://us-central1-mesh-db-stg.cloudfunctions.net/user_requests', {
                        method: 'DELETE',
                        headers: {
                            'content-Type': 'application/json'
                        },
                        body: JSON.stringify(body)
                    });
                    responseData = await response.json();
                } catch (error) {
                    console.log(error);
                }

                loading.value = null;
                if (responseData.code === 200) {
                    entities.value[selectedEntity.value].users = entities.value[selectedEntity.value].users.filter(map => map.uid !== userUID);
                }
                setTimeout(() => {
                    if (responseData.code === 200) {
                        alert("Results:" + responseData.message);
                    } else {
                        try {
                            alert(responseData.message);
                        } catch (error) {
                            alert("Something went wrong when deleting the user");
                        }
                    }
                }, 100);
            };

            return {
                user,
                userMenu,
                entities,
                selectedUser,
                selectedEntity,
                modal,
                loading,
                roleOptions,
                statusOptions,
                toggleUserMenu,
                createEntity,
                createUser,
                editUser,
                deleteUser
            };
        }
    }
</script>

<template>
    <div class="modal-overlay" @click.self="close">
      <div class="modal-content">
        <h2>Edit User</h2>
        <form @submit.prevent="handleSubmit">
          <label>
            First Name:
            <br>
            <input v-model="firstName" type="text" required/>
          </label>
          <br><br>
          <label>
            Last Name:
            <br>
            <input v-model="lastName" type="text" required/>
          </label>
          <br><br>
          <label>
            Company:
            <br>
            <select v-model="company" @change="isNewEntity" required>
                <option v-for="(entityData, entityID) in entities" :key="entityID" :value="entityID">{{ entityData.name }}</option>
                <option class="font-bold" value="new">+ Add</option>
            </select>
          </label>
          <br><br>
          <div v-if="newEntity">
            <label>
                Entity Name:
                <br>
                <input v-model="entityName" type="text" required />
            </label>
            <br><br>
            <label>
                Entity URL:
                <br>
                <input v-model="entityURL" type="text" />
            </label>
            <br><br>
            <label>
                Entity Logo:
                <br>
                <input type="file" accept="image/*" @change="setFile('logo', $event)" />
            </label>
            <br><br>
            <label>
                Entity Icon:
                <br>
                <input type="file" accept="image/*" @change="setFile('icon', $event)" />
            </label>
            <br><br>
          </div>
          <label>
            Email:
            <br>
            <input v-model="email" type="text" required/>
          </label>
          <br><br>
          <label>
            role:
            <br>
            <select v-model="role" required>
              <option value="viewer">viewer</option>
              <!-- <option value="editor">editor</option> -->
              <option value="admin">admin</option>
            </select>
          </label>
          <br><br>
          <!-- <label>
            Permissions:
            <br>
            <input v-model="permissions" type="text"/>
          </label>
          <br><br> -->
          <!-- <label>
            Units:
            <br>
            <input v-model="Units" type="text"/>
          </label>
          <br><br> -->
          <label>
            Profile Picture:
            <br>
            <input type="file" accept="image/*" @change="setFile('profilePic', $event)" />
          </label>
          <br><br>
          <div style="display: flex;">
            <button @click="close" style="margin-right: 20px; height: 30px;">Close</button>
            <button type="submit" :disabled="loading" style="margin-right: 10px; height: 30px;">Submit</button>
            <LoadingAnimation1 v-if="loading"></LoadingAnimation1>
          </div>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  import { ref } from 'vue';
  import LoadingAnimation1 from '../loadingIcons/LoadingAnimation1.vue';
  
  export default {
    name: 'EditUser',
    components: {
      LoadingAnimation1
    },
    props: {
        entities: Object,
        user: Object,
    },
    setup(props, { emit }) {
      const firstName = ref(props.user.firstName);
      const lastName = ref(props.user.lastName);
      const email = ref(props.user.email);
      const entity = ref(props.user.entity);
      const role = ref(props.user.role);
      const company = ref(props.user.company);
      const entityName = ref('');
      const entityURL = ref('');
      const icon = ref(null);
      const logo = ref(null);
      const timeFormat = ref(props.user.timeformat);
      // const permissions = ref({});
      // const units = ref([]);
      const profilePic = ref(props.user.profilePic);
      const loading = ref(false);
      const newEntity = ref(false);

      const isNewEntity = () => {
        if (company.value === 'new') {
            newEntity.value = true;
        }
        else if (newEntity.value) {
            newEntity.value = false;
        }
      };
  
      const handleSubmit = () => {
        loading.value = true;
        const userData = {
          firstName: firstName.value,
          lastName: lastName.value,
          entity: '',
          email: email.value,
          company: '',
          role: role.value,
          // permissions: permissions.value,
          // units: units.value,
          profilePic: profilePic.value
        };

        if (company.value !== 'new') {
            userData.entity = company.value;
            userData.company = props.entities[company.value].name;
        }

        const entityData = {};
        if (newEntity.value) {
            entityData.name = entityName.value;
            entityData.url = entityURL.value;
            entityData.logo = logo.value;
            entityData.icon = icon.value; 
        }

        emit('submitForm', userData, entityData);
      };
  
      const setFile = (type, event) => {
        const file = event.target.files[0];
        if (file) {
          if (type === 'profilePic') {
            profilePic.value = file;
          }
          else if (type === 'icon') {
            icon.value = file;
          }
          else if (type === 'logo') {
            logo.value = file;
          }
        }
      };
  
      const close = () => {
        emit('close');
      };
  
      return {
        firstName, 
        lastName,
        email,
        entity,
        role,
        company,
        entityName,
        entityURL,
        timeFormat,
        // permissions,
        // units,
        profilePic,
        loading,
        newEntity,
        isNewEntity,
        handleSubmit,
        setFile,
        close,
      };
    }
  };
  </script>
  
  <style scoped>
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    width: 300px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    
  }
  
  input {
    width: 100%;
  }
  </style>
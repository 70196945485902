<template>
    <div class="generic-gauge">
        <VuePlotly
            :key="key"
            :data="data"
            :layout="layout"
            :config="config"
        ></VuePlotly>
        <p class="text-sm">{{ lastUpdate }}</p>
    </div>
</template>
  
<script>
    import { ref, onMounted } from 'vue';
    import { VuePlotly } from 'vue3-plotly';
    import { utcToLocal } from '@/tools/TimeFunctions';
    import { collection, query, orderBy, limit, onSnapshot, getDocs} from 'firebase/firestore';
    import { db } from '@/firebase';
  
    export default {
        name: 'GenericGauge',
        components: {
            VuePlotly
        },
        props: {
            template: Object,
            dataID: String,
        },
        setup(props) {
            const interval = Math.round((props.template.range[1] - props.template.range[0]) / (6));
            const key = ref(0);
            const lastUpdate = ref('Last Update:')
            const data = ref([
                {
                    type: "indicator",
                    mode: "gauge+number",
                    value: null,
                    title: { text: props.template.title, font: { size: 24 } },
                    gauge: {
                        axis: { 
                            range: props.template.range,
                            tickvals: [
                                props.template.range[0],
                                props.template.range[0] + interval,
                                props.template.range[0] + interval*2,
                                props.template.range[0] + interval*3,
                                props.template.range[0] + interval*4,
                                props.template.range[0] + interval*5,
                                props.template.range[1]
                            ],
                            // ticktext: [
                            //     `${props.template.range[0]}`,
                            //     `${props.template.range[0] + interval} unit`,
                            //     `${props.template.range[0] + interval*2} unit`,
                            //     `${props.template.range[0] + interval*3} unit`,
                            //     `${props.template.range[1]}`
                            // ],
                            tickwidth: 1,
                            tickcolor: "black"
                        },
                        bar: { color: props.template.color },
                        bgcolor: "white",
                        borderwidth: 2,
                        bordercolor: "gray",
                        steps: props.template.steps,
                    }
                }
            ]);
            const layout = ref({
                width: 275,
                height: 200,
                margin: { t: 60, r: 20, l: 20, b: 20 },
                paper_bgcolor: "lavender",
                font: { color: "black", family: "Arial"}
            });
            const config = ref({displaylogo: false})

            onMounted(() => {
                if (props.template.delta) {
                    data.value[0].mode = "gauge+number+delta";
                    // data.value[0].set('delta', props.template.delta);
                    data.value[0]['delta'] = props.template.delta;
                }
                if (props.template.threshold) {
                    const threshold = {
                        line: { color: "black", width: 4 },
                        thickness: 1,
                        value: props.template.threshold
                    };
                    data.value[0].gauge["threshold"] = threshold;
                }
                getLastUpdate(props.template.source);
                setSnapshots(props.template.source, props.template.field);
            });

            const getLastUpdate = async(subcollection) => {
                const subcollectionRef = collection(db, 'data', props.dataID, subcollection);
                const q = query(subcollectionRef, orderBy("messageUTC", "desc"), limit(1));

                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                    lastUpdate.value = `Last Update: ${utcToLocal(doc.data().messageUTC, '12hour')}`;
                });
                // key.value += 1;
            };

            /**
             * Grab data from a subcollection in the database
             */
            const setSnapshots = (subcollection, field) => {
                const subcollectionRef = collection(db, 'data', props.dataID, subcollection);
                const q = query(subcollectionRef, orderBy('messageUTC', 'desc'), limit(1));
                onSnapshot(q, (snapshot) => {
                    snapshot.docChanges().forEach((change) => {
                        const docData = change.doc.data();
                        lastUpdate.value = `Last Update: ${utcToLocal(docData.messageUTC, '12hour')}`;
                        data.value[0].value = docData[field];
                    });
                    key.value += 1;
                });
            };
        
            return {
                data,
                layout,
                key,
                config,
                lastUpdate
            };
        },
    };
</script>

<style>
    .generic-gauge{
        background-color: white;
        border-radius: 10px;
        padding: 15px;
        /* height: fit-content; */
        margin-bottom: 20px;
        width: fit-content;
    }
</style>
<template>
  <div class="h-screen w-screen bg-gray-300">
    <DefaultHeader></DefaultHeader>
    <div class="main-container">
      <DefaultSidebar></DefaultSidebar>
      <div class="content-area">

        <div class="flex flex-wrap justify-between">
          <!--Create the page title and subtitle-->
          <div class="page-title">
            <h1 class="text-3xl font-bold">{{ pageTitle }}</h1>
            <div v-if="pageSubtitle" v-html="pageSubtitle"></div>
          </div>

          <!--Create links to subpages-->
          <div class="subpage-link-container">
              <a v-for="[pageName, address] in subpageLinks" :key="pageName" :href="address[0]" :class="{ 'bg-indigo-600 shadow-gray-500 shadow-lg hover:bg-indigo-500': address[1] !== 'inactive', 'bg-indigo-400': address[1] === 'inactive' }" class="m-5 p-5 rounded-md py-2 text-lg font-semibold text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">{{ pageName }}</a>
          </div>
        </div>
        <main :class="[pageSubtitle ? 'main-content2' : 'main-content1']">
          <!-- Main content will go here -->
          <slot></slot>
        </main>
      </div>
    </div>
  </div>
</template>
  
<script>
  import DefaultHeader from './Header.vue'
  import DefaultSidebar from './Sidebar.vue'
  
  export default {
    name: 'DefaultLayout',
    components: {
      DefaultHeader,
      DefaultSidebar
    },
    props: {
      pageTitle: String,
      pageSubtitle: String,
      subpageLinks: Map
    },
    // setup(props) {
    //   const setMainHeight = () => {
    //     return props.pageSubtitle ? 'height: calc(100vh - 190px)' : 'height: calc(100vh - 220px)'
    //   };

    //   return {
    //     setMainHeight
    //   };
    // }
  };
</script>
  
<style>
  .layout {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 20px);
    background-color: #E2E2E2;
  }
  
  .main-container {
    /* position: relative; */
    display: flex;
    flex: 1;
  }

  .content-area {
    /* position: relative; */
    flex: 1;
    padding-left: 20px;
    padding-top: 10px;
    padding-right: 20px;
    margin-left: 70px;
  }
  
  .page-title {
    margin: 0;
    display: inline-block;
    background: linear-gradient(to right, white, rgba(255, 255, 255, 0) 100%);
    padding: 5px;
    padding-left: 10px;
    padding-right: 450px;
    margin-bottom: 10px;
    min-width: 150px;
    box-sizing: border-box;
  }

  .page-title h1 {
    margin: 10px;
  }

  .page-title p {
    margin-left: 10px;
  }

  .subpage-link-container {
    float: right;
    height: 100px;
    width: fit-content;
    align-content: center;
    align-items: center;
  }

  .subpage-link {
    display: inline-block;
    background: rgba(96, 168, 250, 0.589);
    margin: 10px;
    padding: 20px;
    width: 150px;
    border-radius: 50px;
    text-align: center;
    color: black;
  }

  .main-content1 {
    height: calc(100vh - 190px);
    overflow-y: auto;
  }

  .main-content2 {
    height: calc(100vh - 220px);
    overflow-y: auto;
  }

</style>
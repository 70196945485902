<template>
    <div class="card-container">
        <div class="card-summary">
            <h2 v-if="friendlyName" class="underline text-xl font-bold">{{ friendlyName }}</h2>
            <p><span class="font-bold">Data ID: </span>{{ dataID }}</p>
            <p><span style="font-weight: bold;">Last Acvive: </span>{{ lastActivity }}</p>
        </div>
        <div class="p-5 rounded-full border-4 h-fit" :style="{ 'border-color': borderColor }">
            <img class="w-12" src="@/assets/unit.png" alt="unit">
        </div>
    </div>
</template>
    
<script>
    import { ref, onMounted } from 'vue';
    import { utcToLocal, timeSimplified } from '@/tools/TimeFunctions';

    export default {
        name: 'UnitCard',
        components: {
        },
        props: {
            unitData: Object,
            dataID: String,
            timeFormat: {
                type: String,
                default: '24hour'
            }
        },
        setup(props) {
            const friendlyName = ref(null);
            const lastActivity = ref(null);
            const borderColor = ref('black')

            onMounted(() => {
                friendlyName.value = props.unitData.friendlyName;
                lastActivity.value = timeSimplified(utcToLocal(props.unitData.lastActivityUTC, props.timeFormat)) || "no activity";

                if (lastActivity.value === 'Current') {
                    borderColor.value = 'green';
                }
                else if (lastActivity.value === 'no activity') {
                    borderColor.value = 'gray';
                }
                else {
                    borderColor.value = 'red';
                }
            });

            return {
                friendlyName,
                lastActivity,
                borderColor,
            };
        },
    }
</script>

<style>
    .card-container {
        display: flex;
        align-items: center;
        background-color: rgb(184, 222, 255);
        border-radius: 10px;
        padding: 10px;
        margin: 10px;
        text-align: left;
        width: 96%;
    }

    /* .card-image {
        padding: 20px;
        width: 100px;
        border-style: solid;
        border-radius: 50%;
    }

    .card-image img {
        width: 45px;
    } */

    .card-summary {
        margin-left: 10px;
        align-content: center;
        width: 100%;
    }

    .card-summary h2 {
        margin-top: 0;
    }

    .card-summary p {
        margin: 0;
    }
</style>
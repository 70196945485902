<template>
  <DefaultLayout
  pageTitle="Dashboard"
  >
    <div class="dashboard-body">
      <div class="unit-container">
        <div class="title">
          <h2 id="unitCount" class="text-xl font-semibold border-b-2 border-black pb-3 pt-2">{{ unitCount }}</h2>
        </div>
        <div class="search">
          <input id="searchbar" type="search" v-model="searchQuery" placeholder="Search Units..." @input="performSearch" />
          <button @click="performSearch" class="search-button" ><img src="@/assets/magnifying_glass.png" alt=""></button>
        </div>
        <div class="shortcuts">
          <button id="all" @click="showAll()" :class="{ 'bg-indigo-600 bg-600 shadow-gray-500 shadow-lg hover:bg-indigo-500': activeShortcut !== 'all' && searchResults.length === 0, 'bg-indigo-400': activeShortcut === 'all' }" class="rounded-md py-2 text-xs font-semibold text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">All</button>
          <button id="activated" @click="showActivated()" :class="{ 'bg-indigo-600 shadow-gray-500 shadow-lg hover:bg-indigo-500': activeShortcut !== 'activated' && searchResults.length === 0, 'bg-indigo-400': activeShortcut === 'activated' }" class="rounded-md px-3 py-2 text-xs font-semibold text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Activated</button>
          <button id="deactivated" @click="showDeactivated()" :class="{ 'bg-indigo-600 shadow-gray-500 shadow-lg hover:bg-indigo-500': activeShortcut !== 'deactivated' && searchResults.length === 0, 'bg-indigo-400': activeShortcut === 'deactivated' }" class="rounded-md px-3 py-2 text-xs font-semibold text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Deactivated</button>
          <button id="notDeployed" @click="showUnused()" :class="{ 'bg-indigo-600 shadow-gray-500 shadow-lg hover:bg-indigo-500': activeShortcut !== 'unused' && searchResults.length === 0, 'bg-indigo-400': activeShortcut === 'unused' }" class="flex items-center rounded-md px-3 py-2 text-xs font-semibold text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Not Deployed</button>
        </div>

        <div v-if="searchResults.length > 0" class="unit-list" :key="searchKey">
          <UnitCard v-for="(map, index) in searchResults" :key="index"
            @click="toUnitDashboard(Array.from(map.keys())[0])"
            class="unitCard"
            :unitData="Array.from(map.values())[0]"
            :dataID="Array.from(map.keys())[0]"
            :timeFormat="timeFormat"
          ></UnitCard>
        </div>
        <div v-else class="unit-list" :key="unitListKey">
          <UnitCard v-for="(map, index) in unitsToDisplay" :key="index"
            @click="toUnitDashboard(Array.from(map.keys())[0])"
            class="unitCard"
            :unitData="Array.from(map.values())[0]"
            :dataID="Array.from(map.keys())[0]"
            :timeFormat="timeFormat"
          ></UnitCard>
        </div>

      </div>
      <div class="map-container">
        <GoogleMap :key="mapKey"
          :unitData="allUnits"
        ></GoogleMap>
      </div>
    </div>
  </DefaultLayout>
</template>
  
<script>
  import { useStore } from 'vuex';
  import { onMounted, ref } from 'vue';
  import { router } from '@/router';
  import { collection, query, where, getDocs } from 'firebase/firestore';
  import { db } from '@/firebase';
  import { timeSimplified, utcToLocal } from '@/tools/TimeFunctions';

  import DefaultLayout from '@/layouts/Layout.vue';
  import UnitCard from '@/components/cards/UnitCard.vue';
  import GoogleMap from '@/components/maps/GoogleMap.vue';
  
  export default {
    name: 'MainDashboard',
    components: {
      DefaultLayout,
      UnitCard,
      GoogleMap
    },
    setup() {
      // Set up reference variables.
      const searchQuery = ref('');
      const searchResults = ref(new Array());
      const searchKey = ref(0);
      const allUnits = ref(new Map());
      const unitsToDisplay = ref(new Array());
      const unitListKey = ref(0);
      const unitCount = ref('');
      const mapKey = ref(1);
      const activeShortcut = ref("");

      // Grab stored values
      const store = useStore();
      const timeFormat = store.state.user.timeFormat;

      /**
       * Runs when the page loads and grabs all the units associated with
       * the user from the database and save it into the map.
       */
      const grabUnits = onMounted(async () => {
        try {
          // Grab the units from the database.
          const entity = store.state.user.entityViewing;
          const q = query(collection(db, "data"), where("entities", "array-contains-any", entity));
          const querySnapshot = await getDocs(q);

          // Save the data of each unit.
          for (const document of querySnapshot.docs) {
            allUnits.value.set(document.id, document.data());
          }

          showActivated();
        } catch (error) {
          console.log(error)
        }
        mapKey.value += 1
      });

      /**
       * Perform a search within the unit data
       */
      const performSearch = () => {
        const query = searchQuery.value;
        searchResults.value.length = 0;

        if (query) {
          for (let [key, value] of allUnits.value) {
            if (key.includes(query) || value.friendlyName.toLowerCase().includes(query.toLowerCase())) {
              searchResults.value.push(new Map([[key, value]]));
            }
          }
          searchKey.value += 1
        }
        
      };

      /**
       * Saves all the active units of an entity and prioritizes any currently
       * running units.
       */
       const showAll = () => {
        activeShortcut.value = "all";
        unitsToDisplay.value.length = 0;
        for (let [key, value] of allUnits.value) {
          unitsToDisplay.value.push(new Map([[key, value]]));
        }
        unitListKey.value += 1;
        unitCount.value = `Total Units = ${unitsToDisplay.value.length}`;
      };

      /**
       * Saves all the active units of an entity and prioritizes any currently
       * running units.
       */
      const showActivated = () => {
        activeShortcut.value = "activated";
        unitsToDisplay.value.length = 0;
        for (let [key, value] of allUnits.value) {
          if (value.active) {
            const lastActive = timeSimplified(utcToLocal(value.lastActivityUTC, timeFormat));
            if (lastActive === 'Current') {
              unitsToDisplay.value.unshift(new Map([[key, value]]));
            }
            else if(lastActive) {
              unitsToDisplay.value.push(new Map([[key, value]]));
            }
          }
        }
        unitListKey.value += 1;
        unitCount.value = `Total Units Activated = ${unitsToDisplay.value.length}`;
      };

      /**
       * Saves all the active units of an entity and prioritizes any currently
       * running units.
       */
       const showDeactivated = () => {
        activeShortcut.value = "deactivated";
        unitsToDisplay.value.length = 0;
        for (let [key, value] of allUnits.value) {
          if (!value.active) {
            unitsToDisplay.value.push(new Map([[key, value]]));
          }
        }
        unitListKey.value += 1;
        unitCount.value = `Total Units Deactivated = ${unitsToDisplay.value.length}`;
      };

      /**
       * Saves all the active units of an entity and prioritizes any currently
       * running units.
       */
       const showUnused = () => {
        activeShortcut.value = "unused";
        unitsToDisplay.value.length = 0;
        for (let [key, value] of allUnits.value) {
          if (value.active && value.lastActivityUTC === "") {
            unitsToDisplay.value.push(new Map([[key, value]]));
          }
        }
        unitListKey.value += 1;
        unitCount.value = `Total Units Undeployed = ${unitsToDisplay.value.length}`;
      };

      /**
       * Navigate the user to a unit dashboard
       * @param {string}  dataID  The data ID of a particular unit
       */
      const toUnitDashboard = (dataID) => {
        router.push(`/dashboard/${dataID}`);
      };

      return {
        searchQuery,
        searchResults,
        searchKey,
        allUnits,
        unitsToDisplay,
        unitListKey,
        unitCount,
        mapKey,
        grabUnits,
        timeFormat,
        performSearch,
        showAll,
        showActivated,
        showDeactivated,
        showUnused,
        activeShortcut,
        toUnitDashboard
      };
    }
  };
</script>
  
<style>
  .dashboard-body {
    display: flex;
    height: calc(100vh - 190px);
  }

  .unit-container {
    flex-grow: 0;
    flex-shrink: 0;
    width: 455px;
    background-color: white;
    overflow: hidden;
    /* direction: rtl; */
    box-sizing: border-box;
  }

  .map-container {
    flex-grow: 1;
    background-color: white;
    height: 100%;
  }

  .title {
    border-bottom-style: solid;
    width: 100%;
    margin: 10px;
  }

  .search {
    display: flex;
    margin: 10px;
  }

  .search input{
    width: 100%;
  }

  .search img {
    width: 25px;
    height: 25px;
  }

  .shortcuts {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    height: 50px;
    padding: 5px;
  }

  .shortcuts button {
    width: 100px;
  }

  .active {
    --tw-shadow-color: #6b7280;
    --tw-shadow: var(--tw-shadow-colored);
    --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .unit-list {
    height: calc(100vh - 350px);
    overflow-y: auto;
    direction: rtl;
    box-sizing: border-box;
  }

  .unitCard:hover {
    background-color: rgb(124, 189, 247);
  }
</style>
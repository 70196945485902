import { createApp } from 'vue';
import App from './App.vue';
import { router } from '@/router';
import store from '@/store';
import './assets/output.css';
import VueTailwindDatepicker from 'vue-tailwind-datepicker';

const app = createApp(App);

app.use(store);
app.use(router);

app.component('DatePicker', VueTailwindDatepicker);

app.mount('#app');
